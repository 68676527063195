export const nationalities = [
    { value: "AF", nome: "Afegão", name: "Afghan" },
    { value: "ZA", nome: "Sul-africano", name: "South African" },
    { value: "AL", nome: "Albanês", name: "Albanian" },
    { value: "DE", nome: "Alemão", name: "German" },
    { value: "AD", nome: "Andorrano", name: "Andorran" },
    { value: "AO", nome: "Angolano", name: "Angolan" },
    { value: "AI", nome: "Anguilano", name: "Anguillan" },
    { value: "AG", nome: "Antiguano", name: "Antiguan" },
    { value: "SA", nome: "Saudita", name: "Saudi" },
    { value: "AR", nome: "Argentino", name: "Argentine" },
    { value: "AM", nome: "Armênio", name: "Armenian" },
    { value: "AU", nome: "Australiano", name: "Australian" },
    { value: "AT", nome: "Austríaco", name: "Austrian" },
    { value: "AZ", nome: "Azeri", name: "Azerbaijani" },
    { value: "BS", nome: "Bahamense", name: "Bahamian" },
    { value: "BD", nome: "Bangladês", name: "Bangladeshi" },
    { value: "BB", nome: "Barbadiano", name: "Barbadian" },
    { value: "BH", nome: "Bareinita", name: "Bahraini" },
    { value: "BE", nome: "Belga", name: "Belgian" },
    { value: "BZ", nome: "Belizenho", name: "Belizean" },
    { value: "BJ", nome: "Beninense", name: "Beninese" },
    { value: "BY", nome: "Bielorrusso", name: "Belarusian" },
    { value: "BO", nome: "Boliviano", name: "Bolivian" },
    { value: "BA", nome: "Bósnio", name: "Bosnian" },
    { value: "BR", nome: "Brasileiro", name: "Brazilian" },
    { value: "BG", nome: "Búlgaro", name: "Bulgarian" },
    { value: "BF", nome: "Burquinense", name: "Burkinabe" },
    { value: "BI", nome: "Burundiano", name: "Burundian" },
    { value: "CV", nome: "Cabo-verdiano", name: "Cape Verdean" },
    { value: "KH", nome: "Cambojano", name: "Cambodian" },
    { value: "CM", nome: "Camaronês", name: "Cameroonian" },
    { value: "CA", nome: "Canadense", name: "Canadian" },
    { value: "QA", nome: "Catariano", name: "Qatari" },
    { value: "KZ", nome: "Cazaque", name: "Kazakh" },
    { value: "TD", nome: "Chadiano", name: "Chadian" },
    { value: "CL", nome: "Chileno", name: "Chilean" },
    { value: "CN", nome: "Chinês", name: "Chinese" },
    { value: "CY", nome: "Cipriota", name: "Cypriot" },
    { value: "CO", nome: "Colombiano", name: "Colombian" },
    { value: "KM", nome: "Comorense", name: "Comoran" },
    { value: "CG", nome: "Congolês", name: "Congolese" },
    { value: "KR", nome: "Sul-coreano", name: "South Korean" },
    { value: "CI", nome: "Costa-marfinense", name: "Ivorian" },
    { value: "CR", nome: "Costarriquenho", name: "Costa Rican" },
    { value: "HR", nome: "Croata", name: "Croatian" },
    { value: "CU", nome: "Cubano", name: "Cuban" },
    { value: "DK", nome: "Dinamarquês", name: "Danish" },
    { value: "DJ", nome: "Djibutiano", name: "Djiboutian" },
    { value: "DM", nome: "Dominicano", name: "Dominican" },
    { value: "EG", nome: "Egípcio", name: "Egyptian" },
    { value: "AE", nome: "Emiradense", name: "Emirati" },
    { value: "EC", nome: "Equatoriano", name: "Ecuadorian" },
    { value: "ER", nome: "Eritreu", name: "Eritrean" },
    { value: "SK", nome: "Eslovaco", name: "Slovak" },
    { value: "SI", nome: "Esloveno", name: "Slovenian" },
    { value: "ES", nome: "Espanhol", name: "Spanish" },
    { value: "US", nome: "Estadunidense", name: "American" },
    { value: "EE", nome: "Estoniano", name: "Estonian" },
    { value: "ET", nome: "Etíope", name: "Ethiopian" },
    { value: "FI", nome: "Finlandês", name: "Finnish" },
    { value: "FR", nome: "Francês", name: "French" },
    { value: "GA", nome: "Gabonês", name: "Gabonese" },
    { value: "GM", nome: "Gambiano", name: "Gambian" },
    { value: "GH", nome: "Ganense", name: "Ghanaian" },
    { value: "GR", nome: "Grego", name: "Greek" },
    { value: "HT", nome: "Haitiano", name: "Haitian" },
    { value: "JP", nome: "Japonês", name: "Japanese" },
    { value: "RU", nome: "Russo", name: "Russian" },
    { value: "ZW", nome: "Zimbabuense", name: "Zimbabwean" }
  ];
  