import { Component } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-auth-home-page',
  standalone: true,
  imports: [RouterModule, TranslateModule],
  templateUrl: './auth-home-page.component.html',
  styleUrl: './auth-home-page.component.scss'
})
export class AuthHomePageComponent {
  constructor(private router: Router ) {
    if (localStorage.getItem('authToken')) {
      this.router.navigate(['/signup']);
    }
   }
}
