import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthHomePageComponent } from './pages/public/auth-home-page/auth-home-page.component';
import { AuthLoginPageComponent } from './pages/public/auth-login-page/auth-login-page.component';
import { AuthSignupPageComponent } from './pages/public/auth-signup-page/auth-signup-page.component';
import { HomePageComponent } from './pages/internal/home-page/auth-home-page.component';
import { AuthRecoveryPageComponent } from './pages/public/auth-recovery-page/auth-recovery-page.component';
import { SystemAboutPageComponent } from './pages/public/system-about-page/system-about-page.component';
import { PublicLayoutComponent } from './layouts/public-layout/public-layout.component';
import { Routes } from '@angular/router';
import { SystemFaqPageComponent } from './pages/public/system-faq-page/system-faq-page.component';
import { TeachersPageComponent } from './pages/internal/teachers-page/teachers-page.component';
import { CoursePageComponent } from './pages/internal/couse-page/course-page.component';
import { ListTeachersPageComponent } from './pages/internal/list-teachers-page/list-teachers-page.component';

export const routes: Routes = [
    {
      path: '',
      component: AuthLayoutComponent,
      children: [
        { path: '', component: AuthHomePageComponent },
        { path: 'login', component: AuthLoginPageComponent },
        { path: 'signup', component: AuthSignupPageComponent },
        { path: 'recovery/:code', component: AuthRecoveryPageComponent },
      ]
    },
    {
      path: '',
      component: PublicLayoutComponent,
      children: [
        { path: 'about', component: SystemAboutPageComponent },
        { path: 'faq', component: SystemFaqPageComponent },
      ]
    },
    {
      path: 'home',
      component: HomePageComponent
    },
    {
      path: 'teachers',
      component: ListTeachersPageComponent
    },
    {
      path: 'teachers/:id',
      component: TeachersPageComponent
    },
    {
      path: 'course/:id',
      component: CoursePageComponent
    },
]