<footer class="bg-background text-white pt-10 font-helvetica">
  <div
    class="container mx-auto flex justify-center-center gap-20 text-center md:text-left"
  >
    <div class="flex-grow-0">
      <h3 class="font-semibold select-none inline-block text-sm uppercase mb-8">
        Institucional
      </h3>
      <ul>
        <li class="mb-2">
          <a href="#" class="font-regular hover:text-primary text-[15px]"
            >Sobre Ensina</a
          >
        </li>
        <li class="mb-2">
          <a href="#" class="font-regular hover:text-primary text-[15px]"
            >Original Ensina</a
          >
        </li>
        <li class="mb-2">
          <a href="#" class="font-regular hover:text-primary text-[15px]"
            >Seja nosso Professor</a
          >
        </li>
      </ul>
    </div>

    <div class="flex-grow-0">
      <h3 class="font-semibold select-none inline-block text-sm uppercase mb-8">
        Ajuda
      </h3>
      <ul>
        <li class="mb-2">
          <a href="#" class="font-regular hover:text-primary text-[15px]"
            >Dúvidas frequentes</a
          >
        </li>
        <li class="mb-2">
          <a href="#" class="font-regular hover:text-primary text-[15px]"
            >Reclamações</a
          >
        </li>
        <li class="mb-2">
          <a href="#" class="font-regular hover:text-primary text-[15px]"
            >Contato</a
          >
        </li>
      </ul>
    </div>

    <div class="flex flex-col items-center flex-1">
      <h3 class="font-semibold select-none inline-block text-sm uppercase mb-8">
        Central de Atendimento
      </h3>
      <p class="text-[34px] font-extralight mb-1">0800-888-0479</p>
      <p class="text-xs font-extralight mb-2 text-center">
        Segunda a sexta das 09h às 17h<br />(exceto feriados)
      </p>
    </div>

    <div class="flex flex-col items-center flex-1">
      <h3
        class="font-extralight text-center select-none text-[12px] uppercase mb-7"
      >
        Acompanhe nossas Redes Sociais
      </h3>
      <div class="flex space-x-4 gap-5 items-center">
        <a href="#" class="hover:opacity-75" title="Instagram">
          <instagram-icon class="hover:text-primary" />
        </a>
        <a href="#" class="hover:opacity-75" title="Tiktok">
          <tiktok-icon class="hover:text-primary" />
        </a>
        <a href="#" class="hover:opacity-75" title="Linkedin">
          <linkedin-icon class="hover:text-primary" />
        </a>
        <a href="#" class="hover:opacity-75" title="Youtube">
          <youtube-icon class="hover:text-primary" />
        </a>
      </div>
    </div>
  </div>
  <div
    class="container mx-auto flex justify-center-center items-center gap-20 text-center md:text-left my-16"
  >
    <div class="flex-grow-0">
      <div class="flex gap-3">
        <h3 class="select-none inline-block font-extralight">
          Lang:
        </h3>
        <button title="Português">
          <img src="./assets/images/brazil-flag.png" class="w-7" />
        </button>
        <button title="">
          <img src="./assets/images/spanish-flag-outline.png" class="w-7" />
        </button>
        <button title="">
          <img src="./assets/images/english-flag-outline.png" class="w-7" />
        </button>
      </div>
    </div>

    <div class="flex-grow-0">
      <div class="flex gap-3 items-center">
        <h3 class="select-none inline-block font-extralight">
          Em breve:
        </h3>
        <a title="Google Play">
          <img src="./assets/images/google-play.png" class="w-36 hover:brightness-200 cursor-pointer" />
        </a>
        <a title="App Store">
          <img src="./assets/images/app-store.png" class="w-36 hover:brightness-200 cursor-pointer" />
        </a>
      </div>
    </div>
    <div class="flex-grow-0">
      <div class="flex gap-3 items-center">
        <h3 class="select-none inline-block font-extralight">
          Site criptografado:
        </h3>
        <a title="">
          <img src="./assets/images/ssl.png" class="w-28 hover:brightness-150 cursor-pointer" />
        </a>
        <a title="">
          <img src="./assets/images/secured.png" class="w-28 hover:brightness-150 cursor-pointer" />
        </a>
        <a title="">
          <img src="./assets/images/pagarme.png" class="w-28 hover:brightness-150 cursor-pointer" />
        </a>
      </div>
    </div>

  </div>

  <div class="mt-8 pt-4 w-full">
    <img src="./assets/images/footer/rounded-bar.svg" class="w-full" />
  </div>
  <div class="text-center md:text-left bg-black pt-3 pb-5 -mt-2">
    <div
      class="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 relative text-gray-400"
    >
      <div class="flex gap-4 items-center -mt-2 font-light">
        <img src="./assets/images/logo-white.svg" class="w-30 mt-2" />
        Todos os direitos reservados
      </div>
      <div class="flex justify-center md:justify-end space-x-4">
        <a href="#" class="hover:text-primary text-[15font-regular px]"
          >Privacidade</a
        >
        <a href="#" class="hover:text-primary text-[15font-regular px]"
          >Termos de uso</a
        >
      </div>
    </div>
  </div>
</footer>