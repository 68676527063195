import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { FooterMinimalComponent } from '../../shared/components/footer-minimal/footer-minimal.component';

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss',
  imports: [RouterOutlet, RouterModule, FooterMinimalComponent,] 
})
export class AuthLayoutComponent {}