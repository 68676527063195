import {
  Component,
  ElementRef,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { FooterComponent } from '../../../shared/components/footer/footer.component';
import KeenSlider from 'keen-slider';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from '../../../shared/components/spinner/spinner.component';
import { TeachersService } from '../../../services/api/teachers/teachers.service';
import { replaceNbspInObject } from '../../../shared/utils/utils';

@Component({
  selector: 'app-list-teachers-page',
  standalone: true,
  imports: [
    RouterModule,
    HeaderComponent,
    FooterComponent,
    CommonModule,
    SpinnerComponent,
  ],
  templateUrl: './list-teachers-page.component.html',
  styleUrl: './list-teachers-page.component.scss',
})
export class ListTeachersPageComponent {
  teachers;
  loading = true;

  constructor(private teacherService: TeachersService) {}

  async ngOnInit() {
    try {
      const response = await this.teacherService.getAll();
      const teachers = response?.results.map((te) => {
        return replaceNbspInObject(te);
      });

      this.teachers = teachers;
      this.loading = false;
    } catch (error) {}
  }
}
