import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstagramIconComponent } from '../icons/instagram-icon';
import { LinkedinIconComponent } from '../icons/linkedin-icon';
import { TikTokIconComponent } from '../icons/tiktok-icon';
import { YoutubeIconComponent } from "../icons/youtube-icon";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-footer-minimal',
  standalone: true,
  imports: [CommonModule, RouterModule, TranslateModule, InstagramIconComponent, TikTokIconComponent, LinkedinIconComponent, YoutubeIconComponent],
  templateUrl: './footer-minimal.component.html',
  styleUrls: ['./footer-minimal.component.css']
})
export class FooterMinimalComponent {
  currentLanguage: string = 'pt-br';

  private storageListener: () => void;

  constructor(public translate: TranslateService) {
    
  }
  ngOnInit(): void {
    const storedLanguage = localStorage.getItem('language') || 'pt-br';
    this.updateOnListem(storedLanguage);

    this.storageListener = () => {
      const updatedLanguage = localStorage.getItem('language') || 'pt-br';
      this.updateOnListem(updatedLanguage);
    };

    window.addEventListener('storage', this.storageListener);
  }

  useLanguage(language: string): void {
    localStorage.setItem("language", language)
    this.currentLanguage = language;
    this.translate.use(language);
  }
  updateOnListem(language: string): void {
    this.currentLanguage = language;
  }
}
