<app-header />
<div class="block min-h-full">
  <div class="relative block min-h-full">
    <div class="min-w-full min-h-full">
      <div class="relative flex items-end overflow-hidden pb-10">
        <img
          [src]="item.image"
          alt="{{ item.title }}"
          class="w-full h-[84vh] min-h-[500px] max-h-[750px] object-cover absolute top-0"
        />
        <div
          class="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-black via-[#00000077] via-transparent to-transparent h-full"
        ></div>
        <div
          class="absolute left-0 bottom-0 right-0 bottom-0 bg-gradient-to-t from-black via-[#00000077] via-transparent to-transparent h-full"
        ></div>
        <div
          class="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-b from-black to-transparent h-24"
        ></div>

        <div class="flex flex-col gap-1 items-start z-0 mt-3 p-10 max-w-[40%]">
          <span class="text-white text-[36px] py-1 font-extralight">
            ORIGINAL
            <span class="ml-2 font-medium">ENSINA </span>
            <span class="text-[52px] mb-[-17px] inline-block align-bottom"
              >®</span
            >
          </span>
          <img [src]="'./assets/images/home/hero-1.png'" class="w-full" />
          <div class="flex gap-8 items-center text-lg w-full text-white innerHtml" [innerHTML]="course?.translates?.[0]?.details">
          </div>
        </div>
        <div class="flex flex-col gap-1 items-start z-0 mt-3">
          <div class="flex gap-8 items-center text-lg w-full">
            <div class="ml-auto px-10 flex gap-3 items-center select-none h-20 text-[14px]">
              <div class=" border-r border-r-white pr-4">
                <span class="text-primary font-semibold">Categoria: </span>
                <span class="text-white">{{course?.category?.translates?.[0].name}}</span>
              </div>
              <div class=" border-r border-r-white pr-4">
                <span class="text-primary font-semibold"
                  >Popularidade: 90%</span
                >
              </div>
              <div
                class="flex flex-col gap-1 overflow-hidden text-nowrap items-center justify-center group text-xl text-black inline-block bg-white rounded-md font-semibold p-2 hover:w-[100px] w-14 h-12 text-center transition-all duration-200 ease-linear mr-16 hover:-mr-[-20px] hover:h-20"
              >
                <span
                  class="text-xs -top-5 relative group-hover:top-0 transition-all duration-300 ease-linear"
                  >Horas totais</span
                >
                <span>45h</span>
                <span
                  class="text-xs -bottom-5 relative group-hover:bottom-0 transition-all duration-300 ease-linear"
                  >8 módulos</span
                >
              </div>
              <button
                href="#"
                class="bg-primary text-white shrink-border px-4 py-3 rounded-lg"
              >
                Comprar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="py-10 bg-black">
    <div
      class="flex w-[60%] min-w-[500px] m-auto gap-3 align-middle items-center"
    >
      <div class="-w-full flex-1">
        <img [src]="'./assets/images/home/hero-1.png'" class="w-full" />
      </div>
      <div class="-w-full flex-1 text-white font-light">
        <h3 class="text-primary font-bold mb-6 text-[24px] leading-7">
          {{course?.translates?.[0]?.titlePersona}}
        </h3>
        <div class="innerHtml" [innerHTML]="course?.translates?.[0]?.textPersona">
        </div>
        <button
          href="#"
          class="bg-transparent text-primary shrink-border px-4 py-3 rounded-lg mt-10"
        >
          Comprar
        </button>
      </div>
    </div>
  </div>

  <div class="py-10 bg-black">
    <h2 class="text-center text-white font-gabriella my-10 relative">
      <span
        aria-hidden="true"
        class="title-stroke absolute text-[50px] tracking-widest left-0 right-0 -top-6 bottom-0 font-gabriella"
        >Conteúdo rico</span
      >
      <span class="relative text-[20px] tracking-wide uppercase">
        Conteúdo rico
      </span>
    </h2>
    <div
      class="flex flex-wrap w-[60%] min-w-[500px] m-auto gap-4 justify-center"
    >
      <div
        *ngFor="let item of course?.translates?.[0].richContent"
        class="w-[49%] aspect-square bg-primary text-white font-light rounded-2xl overflow-hidden relative"
      >
        <img
          [src]="'./assets/images/hero-1.jpg'"
          class="absolute top-0 bottom-0 left-0 right-0 h-full object-cover"
        />
        <div
          class="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-black via-[#00000099] via to-transparent h-full"
        ></div>
        <div class="relative p-10">
          <h3 class="text-primary font-bold text-[16px]">NOVIDADE LEGACY</h3>
          <h3 class="text-white font-bold mb-6 text-[28px] leading-10">
            {{item.title}}
          </h3>
          <div class="mb-4 innerHtml" [innerHTML]="item.text">

          </div>
        </div>
      </div>
     
    </div>
    <button
      href="#"
      class="bg-transparent mx-auto block text-primary shrink-border px-4 py-3 rounded-lg mt-10"
    >
      Comprar
    </button>
  </div>

  <div class="py-10 bg-black px-10">
    <h2 class="text-center text-white font-gabriella my-10 relative">
      <span
        aria-hidden="true"
        class="title-stroke absolute text-[50px] tracking-widest left-0 right-0 -top-6 bottom-0 font-gabriella"
        >Módulos</span
      >
      <span class="relative text-[20px] tracking-wide uppercase">
        Módulos
      </span>
    </h2>
    <div #sliderModules class="keen-slider">
      <div
        class="keen-slider__slide overflow-visible border-2 border-transparent hover:border-primary rounded-lg"
      >
        <div
          [style.background-image]="'url(./assets/images/kitty.jpg)'"
          class="group bg-center min-h-[400px] relative"
        >
          <span
            class="absolute z-10 left-0 right-0 inline-block text-primary rounded-md bg-black text-lg mt-2 mx-auto w-fit"
          >
            Módulo 1
          </span>

          <div
            class="translate-y-[70%] group-hover:translate-y-0 text-center transition-all duration-600 ease-linear"
          >
            <div class="text-[#AEAEB1]">
              <div
                class="bg-gradient-to-t from-black via-[#000000dd] to-transparent group-hover:to-black group-hover:via-[#000000] min-h-24 opacity-100"
              ></div>
              <div class="h-full">
                <div
                  class="text-white text-[16px] font-bold px-3 py-1 -m-10 pb-5 bg-black h-32"
                >
                  Lorem ipsum
                </div>
                <div class="bg-[#191818] text-[14px] py-5 px-4 text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  vitae sollicitudin neque. Suspendisse dictum lacinia diam
                  vitae tristique. Integer tincidunt, ante non ornare gravida,
                  justo ipsum suscipit quam, sed interdum tellus massa eu metus.
                  Quisque lobortis ipsum sed ipsum imperdiet euismod. Nulla non
                  vehicula neque. Vivamus et massa ullamcorper, gravida nisi
                  sed, facilisis purus. Cras consequat hendrerit tristique.
                  Etiam interdum velit id posuere iaculis.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="keen-slider__slide overflow-visible border-2 border-transparent hover:border-primary rounded-lg"
      >
        <div
          [style.background-image]="'url(./assets/images/kitty.jpg)'"
          class="group bg-center min-h-[400px] relative"
        >
          <span
            class="absolute z-10 left-0 right-0 inline-block text-primary rounded-md bg-black text-lg mt-2 mx-auto w-fit"
          >
            Módulo 1
          </span>

          <div
            class="translate-y-[70%] group-hover:translate-y-0 text-center transition-all duration-600 ease-linear"
          >
            <div class="text-[#AEAEB1]">
              <div
                class="bg-gradient-to-t from-black via-[#000000dd] to-transparent group-hover:to-black group-hover:via-[#000000] min-h-24 opacity-100"
              ></div>
              <div class="h-full">
                <div
                  class="text-white text-[16px] font-bold px-3 py-1 -m-10 pb-5 bg-black h-32"
                >
                  Lorem ipsum
                </div>
                <div class="bg-[#191818] text-[14px] py-5 px-4 text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  vitae sollicitudin neque. Suspendisse dictum lacinia diam
                  vitae tristique. Integer tincidunt, ante non ornare gravida,
                  justo ipsum suscipit quam, sed interdum tellus massa eu metus.
                  Quisque lobortis ipsum sed ipsum imperdiet euismod. Nulla non
                  vehicula neque. Vivamus et massa ullamcorper, gravida nisi
                  sed, facilisis purus. Cras consequat hendrerit tristique.
                  Etiam interdum velit id posuere iaculis.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="keen-slider__slide overflow-visible border-2 border-transparent hover:border-primary rounded-lg"
      >
        <div
          [style.background-image]="'url(./assets/images/kitty.jpg)'"
          class="group bg-center min-h-[400px] relative"
        >
          <span
            class="absolute z-10 left-0 right-0 inline-block text-primary rounded-md bg-black text-lg mt-2 mx-auto w-fit"
          >
            Módulo 1
          </span>

          <div
            class="translate-y-[70%] group-hover:translate-y-0 text-center transition-all duration-600 ease-linear"
          >
            <div class="text-[#AEAEB1]">
              <div
                class="bg-gradient-to-t from-black via-[#000000dd] to-transparent group-hover:to-black group-hover:via-[#000000] min-h-24 opacity-100"
              ></div>
              <div class="h-full">
                <div
                  class="text-white text-[16px] font-bold px-3 py-1 -m-10 pb-5 bg-black h-32"
                >
                  Lorem ipsum
                </div>
                <div class="bg-[#191818] text-[14px] py-5 px-4 text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  vitae sollicitudin neque. Suspendisse dictum lacinia diam
                  vitae tristique. Integer tincidunt, ante non ornare gravida,
                  justo ipsum suscipit quam, sed interdum tellus massa eu metus.
                  Quisque lobortis ipsum sed ipsum imperdiet euismod. Nulla non
                  vehicula neque. Vivamus et massa ullamcorper, gravida nisi
                  sed, facilisis purus. Cras consequat hendrerit tristique.
                  Etiam interdum velit id posuere iaculis.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="keen-slider__slide overflow-visible border-2 border-transparent hover:border-primary rounded-lg"
      >
        <div
          [style.background-image]="'url(./assets/images/kitty.jpg)'"
          class="group bg-center min-h-[400px] relative"
        >
          <span
            class="absolute z-10 left-0 right-0 inline-block text-primary rounded-md bg-black text-lg mt-2 mx-auto w-fit"
          >
            Módulo 1
          </span>

          <div
            class="translate-y-[70%] group-hover:translate-y-0 text-center transition-all duration-600 ease-linear"
          >
            <div class="text-[#AEAEB1]">
              <div
                class="bg-gradient-to-t from-black via-[#000000dd] to-transparent group-hover:to-black group-hover:via-[#000000] min-h-24 opacity-100"
              ></div>
              <div class="h-full">
                <div
                  class="text-white text-[16px] font-bold px-3 py-1 -m-10 pb-5 bg-black h-32"
                >
                  Lorem ipsum
                </div>
                <div class="bg-[#191818] text-[14px] py-5 px-4 text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  vitae sollicitudin neque. Suspendisse dictum lacinia diam
                  vitae tristique. Integer tincidunt, ante non ornare gravida,
                  justo ipsum suscipit quam, sed interdum tellus massa eu metus.
                  Quisque lobortis ipsum sed ipsum imperdiet euismod. Nulla non
                  vehicula neque. Vivamus et massa ullamcorper, gravida nisi
                  sed, facilisis purus. Cras consequat hendrerit tristique.
                  Etiam interdum velit id posuere iaculis.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="keen-slider__slide overflow-visible border-2 border-transparent hover:border-primary rounded-lg"
      >
        <div
          [style.background-image]="'url(./assets/images/kitty.jpg)'"
          class="group bg-center min-h-[400px] relative"
        >
          <span
            class="absolute z-10 left-0 right-0 inline-block text-primary rounded-md bg-black text-lg mt-2 mx-auto w-fit"
          >
            Módulo 1
          </span>

          <div
            class="translate-y-[70%] group-hover:translate-y-0 text-center transition-all duration-600 ease-linear"
          >
            <div class="text-[#AEAEB1]">
              <div
                class="bg-gradient-to-t from-black via-[#000000dd] to-transparent group-hover:to-black group-hover:via-[#000000] min-h-24 opacity-100"
              ></div>
              <div class="h-full">
                <div
                  class="text-white text-[16px] font-bold px-3 py-1 -m-10 pb-5 bg-black h-32"
                >
                  Lorem ipsum
                </div>
                <div class="bg-[#191818] text-[14px] py-5 px-4 text-left">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                  vitae sollicitudin neque. Suspendisse dictum lacinia diam
                  vitae tristique. Integer tincidunt, ante non ornare gravida,
                  justo ipsum suscipit quam, sed interdum tellus massa eu metus.
                  Quisque lobortis ipsum sed ipsum imperdiet euismod. Nulla non
                  vehicula neque. Vivamus et massa ullamcorper, gravida nisi
                  sed, facilisis purus. Cras consequat hendrerit tristique.
                  Etiam interdum velit id posuere iaculis.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-3 justify-center mt-10">
      <button
        (click)="slider.moveToIdx(i * 4, true)"
        *ngFor="let slide of dotHelper; let i = index"
        [class]="
          'rounded-full border border-primary w-4 h-4 cursor-pointer ' +
          (i === slider?.track?.details?.abs ? 'bg-white border-white' : '')
        "
      ></button>
    </div>
  </div>

  <div class="py-10 bg-black px-10">
    <h2 class="text-center text-white font-gabriella my-10 relative">
      <span
        aria-hidden="true"
        class="title-stroke absolute text-[50px] tracking-widest left-0 right-0 -top-6 bottom-0 font-gabriella"
        >Custo benefício</span
      >
      <span class="relative text-[20px] tracking-wide uppercase">
        Custo benefício
      </span>
    </h2>
    <div
      class="max-w-[1000px] mx-auto bg-gradient-to-b from-[#e60341] to-[#191717] via-[#e60341] bg-opacity-100 rounded-xl p-1 pt-2"
    >
      <div
        class="w-full rounded-xl bg-gradient-to-b from-black to-[#191717] via-black p-10"
      >
        <div class="text-white text-center py-5">
          <h3 class="text-[24px] mb-5 font-semibold">
            Essa será sua Escolha Final!
          </h3>
          <div>
            <p>Desde a última vez que eu abri portas para o Projeto Nômade</p>
            <p>Milionário, milhares de pessoas pedem novas vagas.</p>
            <p>
              Chegou o dia de Você ter acesso as grandes vantagens desse método…
            </p>
          </div>
        </div>
        <div class="flex flex-wrap text-white gap-10 mt-5">
          <div class="flex-1 min-w-[30%]">
            <h4 class="font-semibold text-[18px] mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h4>
            <div class="text-[13px]">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                tempus eget ante nec pulvinar. Integer ullamcorper ac risus eget
                bibendum. Morbi eleifend lectus a euismod varius. Quisque
                malesuada ornare vulputate. Etiam est augue, mattis et convallis
                ut, laoreet id lectus. Aliquam vel convallis diam. Vestibulum
                tincidunt velit id lorem rhoncus ullamcorper. Cras accumsan
                sapien in turpis rutrum gravida.
              </p>
            </div>
          </div>
          <div class="flex-1 min-w-[30%]">
            <h4 class="font-semibold text-[18px] mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h4>
            <div class="text-[13px]">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                tempus eget ante nec pulvinar. Integer ullamcorper ac risus eget
                bibendum. Morbi eleifend lectus a euismod varius. Quisque
                malesuada ornare vulputate. Etiam est augue, mattis et convallis
                ut, laoreet id lectus. Aliquam vel convallis diam. Vestibulum
                tincidunt velit id lorem rhoncus ullamcorper. Cras accumsan
                sapien in turpis rutrum gravida.
              </p>
            </div>
          </div>
          <div class="flex-1 min-w-[30%]">
            <h4 class="font-semibold text-[18px] mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h4>
            <div class="text-[13px]">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                tempus eget ante nec pulvinar. Integer ullamcorper ac risus eget
                bibendum. Morbi eleifend lectus a euismod varius. Quisque
                malesuada ornare vulputate. Etiam est augue, mattis et convallis
                ut, laoreet id lectus. Aliquam vel convallis diam. Vestibulum
                tincidunt velit id lorem rhoncus ullamcorper. Cras accumsan
                sapien in turpis rutrum gravida.
              </p>
            </div>
          </div>
          <div class="flex-1 min-w-[30%]">
            <h4 class="font-semibold text-[18px] mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h4>
            <div class="text-[13px]">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                tempus eget ante nec pulvinar. Integer ullamcorper ac risus eget
                bibendum. Morbi eleifend lectus a euismod varius. Quisque
                malesuada ornare vulputate. Etiam est augue, mattis et convallis
                ut, laoreet id lectus. Aliquam vel convallis diam. Vestibulum
                tincidunt velit id lorem rhoncus ullamcorper. Cras accumsan
                sapien in turpis rutrum gravida.
              </p>
            </div>
          </div>
          <div class="flex-1 min-w-[30%]">
            <h4 class="font-semibold text-[18px] mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h4>
            <div class="text-[13px]">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                tempus eget ante nec pulvinar. Integer ullamcorper ac risus eget
                bibendum. Morbi eleifend lectus a euismod varius. Quisque
                malesuada ornare vulputate. Etiam est augue, mattis et convallis
                ut, laoreet id lectus. Aliquam vel convallis diam. Vestibulum
                tincidunt velit id lorem rhoncus ullamcorper. Cras accumsan
                sapien in turpis rutrum gravida.
              </p>
            </div>
          </div>

          <div class="flex-1 min-w-[30%]">
            <h4 class="font-semibold text-[18px] mb-3">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </h4>
            <div class="text-[13px]">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
                tempus eget ante nec pulvinar. Integer ullamcorper ac risus eget
                bibendum. Morbi eleifend lectus a euismod varius. Quisque
                malesuada ornare vulputate. Etiam est augue, mattis et convallis
                ut, laoreet id lectus. Aliquam vel convallis diam. Vestibulum
                tincidunt velit id lorem rhoncus ullamcorper. Cras accumsan
                sapien in turpis rutrum gravida.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      href="#"
      class="bg-transparent text-primary shrink-border px-4 py-3 rounded-lg mt-10 mx-auto block"
    >
      Comprar
    </button>
  </div>

  <div class="py-10 bg-black px-10">
    <div class="max-w-[1080px] mx-auto relative">
      <img
        [src]="'./assets/images/lights-1.jpg'"
        aria-hidden="true"
        class="absolute left-0 right-0 top-0 w-[800px] mx-auto select-none pointer-events-none"
      />
      <div class="text-white text-center py-5 relative">
        <h3 class="text-[24px] mb-5 font-semibold">
          Passo a Passo do Aprendizado
        </h3>
        <div class="max-w-[500px] mx-auto">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In justo
            nisl, cursus sed semper nec, aliquam non mi. Vivamus blandit sapien
            elit, a commodo arcu pellentesque in.
          </p>
        </div>
      </div>
      <img
        [src]="'./assets/images/pc.png'"
        class="w-full max-w-[700px] mx-auto relative z-20"
      />

      <div
        class="flex flex-wrap justify-center text-white gap-[1%] -mt-3 relative"
      >
        <div
          class="flex-1 max-w-[25%] w-full bg-gradient-to-b from-[#E60341] to-[#E6034133] rounded-3xl px-5 py-10 text-center"
        >
          <h4 class="font-semibold text-[22px] mb-5">Lorem</h4>
          <div class="text-[13px]">
            <p class="text-[#A1A1A4] text-[13px]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse molestie, lacus et pharetra aliquet, sapien metus
              dignissim ligula, eu sagittis leo urna in ligula.
            </p>
          </div>
        </div>
        <div
          class="flex-1 max-w-[25%] w-full bg-gradient-to-b from-[#E60341] to-[#E6034133] rounded-3xl px-5 py-10 text-center"
        >
          <h4 class="font-semibold text-[22px] mb-5">Lorem</h4>
          <div class="text-[13px]">
            <p class="text-[#A1A1A4] text-[13px]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse molestie, lacus et pharetra aliquet, sapien metus
              dignissim ligula, eu sagittis leo urna in ligula.
            </p>
          </div>
        </div>
        <div
          class="flex-1 max-w-[25%] w-full bg-gradient-to-b from-[#E60341] to-[#E6034133] rounded-3xl px-5 py-10 text-center"
        >
          <h4 class="font-semibold text-[22px] mb-5">Lorem</h4>
          <div class="text-[13px]">
            <p class="text-[#A1A1A4] text-[13px]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse molestie, lacus et pharetra aliquet, sapien metus
              dignissim ligula, eu sagittis leo urna in ligula.
            </p>
          </div>
        </div>
        <div
          class="flex-1 max-w-[25%] w-full bg-gradient-to-b from-[#E60341] to-[#E6034133] rounded-3xl px-5 py-10 text-center"
        >
          <h4 class="font-semibold text-[22px] mb-5">Lorem</h4>
          <div class="text-[13px]">
            <p class="text-[#A1A1A4] text-[13px]">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse molestie, lacus et pharetra aliquet, sapien metus
              dignissim ligula, eu sagittis leo urna in ligula.
            </p>
          </div>
        </div>
      </div>
    </div>

    <button
      href="#"
      class="bg-transparent text-primary shrink-border px-4 py-3 rounded-lg mt-10 mx-auto block"
    >
      Comprar
    </button>
  </div>

  <div class="py-10 bg-black">
    <div
      [style.background-image]="'url(./assets/images/lights-1.jpg), url(./assets/images/lights-1.jpg)'"
      class="flex w-[80%] min-w-[500px] m-auto gap-20 align-middle bg-blend-exclusion items-center bg-contain bg-no-repeat bg-[position:left,_center]"
    >
      <div
        class="max-w-[100%] flex-1 relative flex items-center">
        <div class="bg-black p-5 flex text-white w-10/12 gap-10">
          <div>
            <div class="bg-gradient-to-tr from-primary to-tertiary h-32 w-32 rounded-full p-1 overflow-hidden cursor-pointer transition-all duration-150 ease-in hover:brightness-125">
              <img [src]="'./assets/images/hero-1.jpg'" class="w-full h-full rounded-full object-cover object-center"/>
            </div>
          </div>
          <div class="text-center w-full">
            <span class="font-bold">Acesse as redes sociais</span>
          </div>
        </div>
        <div class="iphone-x w-2/12 overflow-hidden !h-[550px]">
          <img [src]="'./assets/images/hero-1.jpg'" class="h-full w-full object-cover object-center" />
          <i></i>
          <b></b>
        </div>
      </div>
      <div class="max-w-[30%] flex-1 text-white font-light">
        <h3 class="text-white font-bold text-[42px] font-bolder leading-7 font-gabriella uppercase">
          {{course?.teacher?.name}}
        </h3>
        <h3 class="text-primary uppercase font-bold mb-6 text-[24px] leading-7 font-gabriella">
          {{course?.teacher?.translates?.[0]?.career}}
        </h3>
        <p class="mb-5">
          <strong class="font-bold">Lorem ipsum dolor sit amet</strong>,
          consectetur adipiscing elit. Sed ut porttitor velit. Nullam viverra
          sapien sed tortor fermentum, vel dapibus magna eleifend. Sed nec velit
          ac nisi scelerisque tristique. In hac habitasse platea dictumst.
          Vivamus nec consectetur ipsum.
        </p>
        <p>
          Curabitur semper tincidunt justo, et vehicula lectus posuere vel.
          Donec consequat neque sit amet sapien sagittis, vel hendrerit turpis
          tincidunt. Nulla facilisi. Suspendisse potenti. Ut tincidunt ante non
          ipsum vestibulum, eu tempor dolor lobortis. Sed viverra urna sed
          aliquam volutpat.
        </p>
        <button
          href="#"
          class="bg-white text-black font-bold shrink-border before:border-white px-4 py-3 rounded-lg mt-10"
        >
          Conheça mais
        </button>
      </div>
    </div>
  </div>

  <div class="pt-24 pb-52 bg-black">
    <div class="bg-gradient-to-r from-[#E60341] to-[#80053D] text-white">
      <div class="flex justify-center items-center gap-10 max-w-[1080px] mx-auto">
        <img
          [src]="'./assets/images/selo-garantia.png'"
          class="max-w-[200px] -mt-5 -mb-5 mx-auto flex-1"
        />
        <div class="uppercase text-[36px] flex-1 text-center font-bold">Risco zero</div>
        <div class="flex-1 text-[15px]">
          <p class="mb-3">
            Se por algum motivo o curso não atender às suas expectativas,
            você tem 7 dias de garantia.
          </p>
          <p>Basta nos enviar uma mensagem e devolveremos o seu dinheiro.</p>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-10 pb-40 bg-black px-10">
    <h2 class="text-center text-white font-gabriella my-10 relative">
      <span
        aria-hidden="true"
        class="title-stroke absolute text-[50px] tracking-widest left-0 right-0 -top-6 bottom-0 font-gabriella"
        >Perguntas frequentes</span
      >
      <span class="relative text-[20px] tracking-wide uppercase">
        Perguntas frequentes
      </span>
    </h2>
    <div
      class="flex w-[80%] min-w-[500px] m-auto gap-10 align-middle items-center"
    >
      <div
        class="max-w-[65%] flex-1 relative flex items-center">
         <img [src]="'./assets/images/faq-course-default-image.jpg'" class="w-full max-h-[400px] rounded-lg object-cover object-center"/>
      </div>
      <div class="max-w-[35%] flex-1 font-light max-h-[400px] overflow-y-auto pr-2 scrollable text-[#dfdfdf] text-[15px]">
        <div class="border-b border-primary" *ngFor="let question of questions; let i = index">
          <div
            (click)="toggleAccordion(i)"
            class="my-4 ml-3 pr-10 relative cursor-pointer font-semibold hover:text-white">
            {{question.question}}
            <img
              [ngClass]="isOpen(i) ? 'rotate-180' : ''"
              [src]="'./assets/images/chevron.svg'"
              class="w-3 absolute top-3 right-1" />
          </div>
          <div
            #content
            [style.maxHeight]="isOpen(i) ? content.scrollHeight + 'px' : '0'"
            class="max-h-0 overflow-hidden pl-6 transition-all duration-300 ease-in-out text-white">
            <p class="mb-5">
              <strong class="font-bold">Lorem ipsum dolor sit amet</strong>,
              consectetur adipiscing elit. Sed ut porttitor velit. Nullam viverra
              sapien sed tortor fermentum, vel dapibus magna eleifend. Sed nec velit
              ac nisi scelerisque tristique. In hac habitasse platea dictumst.
              Vivamus nec consectetur ipsum.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer />
