import { Component, ElementRef, ViewChild } from '@angular/core';
import { InputComponent } from '../../../shared/components/form/input';
import { Router, RouterModule } from '@angular/router';
import { FacebookCircleIconComponent } from '../../../shared/components/icons/facebook-circle-icon';
import { GoogleCircleIconComponent } from '../../../shared/components/icons/google-circle-icon';
import { AuthHomePageComponent } from '../auth-home-page/auth-home-page.component';
import { AppleCircleIconComponent } from '../../../shared/components/icons/apple-circle-icon';
import { z } from 'zod';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from '../../../services/api/auth/auth.service';
import { ProfileService } from '../../../services/profile.service';
import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
import { HeaderMinimalComponent } from "../../../shared/components/header-minimal/header-minimal.component";
import { TranslateModule, TranslateService } from '@ngx-translate/core';

const formSchema = z.object({
  email: z.string().email('E-mail inválido'),
  password: z.string().min(8, 'Minímo de 8 caracteres'),
});

const passwordFormSchema = z.object({
  email: z.string().email('E-mail inválido'),
});

@Component({
  selector: 'app-auth-login-page',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    ReactiveFormsModule,
    RouterModule,
    FacebookCircleIconComponent,
    GoogleCircleIconComponent,
    AuthHomePageComponent,
    AppleCircleIconComponent,
    HeaderMinimalComponent,
    TranslateModule
],
  templateUrl: './auth-login-page.component.html',
  styleUrl: './auth-login-page.component.scss',
})
export class AuthLoginPageComponent {
  @ViewChild('wrapper') wrapper!: ElementRef;

  userForm: FormGroup;
  passwordForm: FormGroup;
  formErrors: any = {};
  environment = environment;
  screen = 'login';
  sendPasswordStatus: any = null

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private profileService: ProfileService,
  ) {
    if (localStorage.getItem('authToken')) {
      this.router.navigate(['/signup']);
    }
    this.userForm = this.fb.group({
      email: [''],
      password: [''],
    });
    this.passwordForm = this.fb.group({
      email: [''],
    });
  }


  ngAfterViewInit() {
    setTimeout(() => {
      const divHeight = this.wrapper.nativeElement.clientHeight;
      this.wrapper.nativeElement.style.height = `${divHeight}px`;
    }, 500);
  }


  async login() {
    const formValidation = formSchema.safeParse(this.userForm.value);
    if (!formValidation.success) {
      this.formErrors = formValidation.error.formErrors.fieldErrors;
      return;
    }

    const body = {
      email: this.userForm.value.email,
      password: this.userForm.value.password,
    };

    const signUpResponse = await this.authService.login(body);
    if (signUpResponse?.session) {
      localStorage.setItem('authToken', JSON.stringify(signUpResponse.session));
      this.profileService.getProfile().then((profile) => {
        if (profile.state == 'FINISHED') {
          this.router.navigate(['/home']);
        } else {
          this.router.navigate(['/signup']);
        }
      });
    }
  }

  async sendRecover() {
    this.formErrors={}
    const formValidation = passwordFormSchema.safeParse(this.passwordForm.value);
    if (!formValidation.success) {
      this.formErrors = formValidation.error.formErrors.fieldErrors;
      return;
    }
    this.sendPasswordStatus='loading';
    try {
      const sendRecoverResponse = await this.authService.sendRecoverLink({
        email: this.passwordForm.value.email
      });
      if(sendRecoverResponse.messageId) {
      }
    } catch (error) {
    }
    this.sendPasswordStatus='finished';
  }

  toggleScreen(screen:string) {
    this.screen=screen
    this.formErrors={}
    this.sendPasswordStatus=null
  }
}
