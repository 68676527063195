<div class="auth-wrapper flex flex-col h-full">
  <div>
    <app-header-minimal></app-header-minimal>
    <router-outlet></router-outlet>
  </div>
  <div class="mt-auto">
    <img src="./assets/images/divider.svg" class="w-full select-none" />    
    <app-footer-minimal/>
  </div>
</div>
