import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-steps',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './steps.component.html',
  styleUrl: './steps.component.scss'
})
export class StepsComponent {
  @Input() steps: string[] = ['aaaa'];
  @Input() currentStep: number = 0;
  @Output() stepClicked = new EventEmitter<number>();

  onStepClick(index: number): void {
    if (index <= this.currentStep) {
      this.stepClicked.emit(index);
    }
  }
}
