<app-header-minimal></app-header-minimal>
<div class="flex flex-col justify-center items-center min-h-[500px] my-12">
  <div
    #wrapper
    class="bg-dark-gradient w-[450px] min-h-[300px] rounded-xl relative overflow-hidden"
  >
    <div
      aria-hidden="true"
      class="absolute pointer-events-none -bottom-10 -left-20 right-0 bg-[url('/assets/images/bg-lines.png')] bg-contain bg-no-repeat h-2/6"
    ></div>
    <div
      aria-hidden="true"
      class="absolute pointer-events-none bg-no-repeat -top-5 left-0 -right-10 bg-[url('/assets/images/bg-lines.png')] bg-contain bg-right h-2/6"
    ></div>
    <div class="pt-10 px-16 flex flex-col items-center justify-center">
      <a [routerLink]="'/'">
        <img
          [src]="'./assets/images/logo-black.svg'"
          alt="Logo Ensina"
          class="w-100 mt-3 mb-1 p-2"
        />
      </a>
    </div>
    <form
      [formGroup]="userForm"
      class="transition-opacity duration-500 ease-in-out overflow-hidden"
      [ngClass]="{
        'opacity-100': screen == 'login',
        'opacity-0 pointer-events-none max-h-0': screen != 'login'
      }"
    >
      <div
        class="relative pb-10 px-16 flex flex-col items-center justify-center"
      >
        <app-input
          class="w-full mt-7"
          [label]="'FORMS.FIELDS.EMAIL' | translate"
          formControlName="email"
          [error]="formErrors.email"
        />
        <app-input
          class="w-full mt-2"
          [label]="'FORMS.FIELDS.PASSWORD' | translate"
          type="password"
          formControlName="password"
          [error]="formErrors.password"
        />
        <div class="w-full">
          <button
            class="text-primary text-right ml-auto block text-sm -mt-1"
            (click)="toggleScreen('password')"
          >
            {{ 'PAGES.LOGIN.FORGOT_PASSWORD' | translate }}
          </button>
        </div>
        <a
          (click)="login()"
          class="bg-primary py-4 px-20 rounded-lg cursor-pointer font-semibold text-[16px] mt-10 text-white w-full text-center"
        >
          {{ 'PAGES.LOGIN.BUTTON_LOGIN' | translate }}
        </a>

        <div class="mt-10 relative w-full text-center">
          <div class="flex items-center">
            <div class="flex-grow h-[2px] bg-white"></div>
            <span class="px-2">{{ 'PAGES.LOGIN.OR' | translate }}</span>
            <div class="flex-grow h-[2px] bg-white"></div>
          </div>
        </div>
        <div class="flex gap-8 justify-center mt-5">
          <a
            [href]="environment.apiUrl + '/api/auth/google'"
            class="text-primary hover:text-black"
          >
            <google-circle-icon [size]="40" />
          </a>
        </div>
        <a class="text-sm mt-8 p-1" [routerLink]="'/signup'">
          {{ 'PAGES.LOGIN.NO_ACCOUNT' | translate }}
          <span class="font-semibold">{{ 'PAGES.LOGIN.SIGNUP' | translate }}</span>
        </a>
      </div>
    </form>
    <form
      [formGroup]="passwordForm"
      class="transition-opacity duration-500 ease-in-out overflow-hidden"
      [ngClass]="{
        'opacity-100': screen == 'password',
        'opacity-0 pointer-events-none max-h-0': screen != 'password'
      }"
    >
      <div
        class="relative pb-10 px-16 flex flex-col items-center justify-center"
      >
        <app-input
          class="w-full mt-7 mb-1"
          [label]="'FORMS.FIELDS.EMAIL' | translate"
          formControlName="email"
          [error]="formErrors.email"
          [ngClass]="{
            'opacity-80 pointer-events-none': sendPasswordStatus=='finished'
          }"
        />
        <p class="leading-snug">
          {{ 'PAGES.RESET_PASSWORD.DESCRIPTION' | translate }}
        </p>
        <button
          (click)="sendRecover()"
          class="bg-primary py-4 px-20 rounded-lg cursor-pointer font-semibold text-[16px] mt-10 text-white w-full text-center"
          [disabled]="sendPasswordStatus!=null"
        >
          {{ sendPasswordStatus=='finished'
            ? ('PAGES.RESET_PASSWORD.BUTTON_SENT' | translate)
            : ('PAGES.RESET_PASSWORD.BUTTON' | translate) }}
        </button>
        <button
          (click)="toggleScreen('login')"
          class="bg-white border-primary border py-4 px-20 rounded-lg cursor-pointer font-semibold text-[16px] mt-10 text-primary w-full text-center"
          [disabled]="sendPasswordStatus=='loading'"
        >
          {{ 'PAGES.RESET_PASSWORD.BACK_TO_LOGIN' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>
