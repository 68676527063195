import { Injectable } from '@angular/core';
import API from '../../../auth/auth-interceptor.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  private apiUrl = environment.apiUrl;

  async getAll() {
    try {
      const response = await API.get(`${this.apiUrl}/api/course`);
      return response.data;
    } catch (error) {
      console.error('Get teacher error:', error);
      throw error;
    }
  }
  
  async get(id) {
    try {
      const response = await API.get(`${this.apiUrl}/api/course/${id}`);
      return response.data;
    } catch (error) {
      console.error('Get teacher error:', error);
      throw error;
    }
  }
}