import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter, Router, Routes } from '@angular/router';
import { AppComponent } from './app/app.component';
import { ProfileService } from './app/services/profile.service';
import { of } from 'rxjs';
import { appConfig } from './app/app.config';

// Initialize profile data before bootstrapping
export function initializeApp(profileService: ProfileService, router: Router) {
  return () => {
    const authToken = localStorage.getItem("authToken");
    const currentRoute = router.url;
    const publicRoutes = ['/signup', '/login', '/recovery', '/admin/dashboard'];

    if (!authToken) {
      if (!publicRoutes.some((route) => currentRoute.startsWith(route)) && currentRoute!='/') {
        router.navigate(['/login']);
      }
      return Promise.resolve(null);
    }
    return profileService.getProfile().then(
      (profile) => {
        if(profile) {
          if (publicRoutes.some((route) => currentRoute.startsWith(route))) {
            router.navigate(['/home']);
          }
          // switch (profile.state) {
          //   case "FINISHED":
          //     break;
          //   case "PENDING_VALIDATION":
          //     router.navigate(['/signup'], { state: { initialSlide: 3 } });
          //     break;
          //   case "UNFINISHED":
          //     router.navigate(['/signup'], { state: { initialSlide: 1 } });
          //     break;

          //   default:
          //     break;
          // }
        }
      },
      (error) => {
        localStorage.removeItem("authToken")
        return of(null); // Continue with initialization
      }
    );
  };
}


bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));