import { Component } from '@angular/core';
import { InputComponent } from '../../../shared/components/form/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HeaderMinimalComponent } from "../../../shared/components/header-minimal/header-minimal.component";


@Component({
  selector: 'app-system-faq-page',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    RouterModule,
    HeaderMinimalComponent
],
  templateUrl: './system-faq-page.component.html',
  styleUrl: './system-faq-page.component.scss',
})
export class SystemFaqPageComponent {
  categories = [
    {
      "name": "Geral",
      "description": "",
      "languageCode": "pt-BR",
      "id": "221",
    },
    {
      "name": "Sobre a Ensina XYZ",
      "description": "",
      "languageCode": "pt-BR",
      "id": "222",
    },
    {
      "name": "Como começar",
      "description": "",
      "languageCode": "pt-BR",
      "id": "223",
    },
  ]

  questions = [
    {
      "question": "Como posso encontrar um curso?",
      "answer": "Each plan varies in features, benefits and pricing. The Free plan offers basic features at no cost, while higher-tier plans provide more advanced features, increased usage limits, and dedicated support.",
      "languageCode": "pt-br",
      "id": "123",
    },
    {
      "question": "Como posso encontrar um curso?",
      "answer": "Each plan varies in features, benefits and pricing. The Free plan offers basic features at no cost, while higher-tier plans provide more advanced features, increased usage limits, and dedicated support.",
      "languageCode": "pt-br",
      "id": "123",
    },
  ]
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {

  }

  ngOnInit(): void {

  }
}
