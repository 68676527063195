<app-header />
<div class="min-h-[50vh] flex justify-center items-center" *ngIf="loading">
  <app-spinner />
</div>
<div class="block min-h-full" *ngIf="!loading">
  <div class="relative block min-h-full">
    <div class="min-w-full min-h-full">
      <div class="relative flex items-end justify-between overflow-hidden pb-0">
        <img
          [src]="item.image"
          class="w-full h-[84vh] min-h-[500px] max-h-[750px] object-cover absolute top-0"
        />
        <div
          class="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-r from-black via-[#00000088] to-transparent h-full"
        ></div>
        <div
          class="absolute left-0 bottom-0 right-0 bottom-0 bg-gradient-to-t from-black via-[#00000077] via-transparent to-transparent h-full"
        ></div>
        <div
          class="absolute left-0 top-0 right-0 bottom-0 bg-gradient-to-b from-black to-transparent h-24"
        ></div>

        <div
          class="flex flex-col gap-1 items-start z-0 mt-3 px-10 py-28 max-w-[35%]"
        >
          <div class="w-full flex-1 text-white font-light">
            <h3
              class="text-white font-bold text-[42px] font-bolder leading-7 font-gabriella uppercase"
            >
              {{ teacher.name }}
            </h3>
            <h3
              class="text-primary uppercase font-bold mb-6 text-[24px] leading-7 font-gabriella"
            >
              {{teacher?.translates?.[0]?.career}}
            </h3>
            <div
              class="text-[14px] leading-[22px] innerHtml"
              [innerHTML]="teacher?.translates?.[0]?.introText"
            ></div>
          </div>
        </div>
        <div
          class="flex flex-col gap-1 items-start z-0 mt-3 px-10 py-20 max-w-[55%]"
          *ngIf="teacher?.translates?.[0]?.testimonial"
        >
          <div class="flex gap-8 items-center text-lg w-full">
            <div
              class="w-full flex-1 text-white font-light text-[14px] leading-[22px]"
            >
              <h3
                class="text-primary uppercase font-bold mb-6 text-[32px] leading-7 font-gabriella"
              >
                Um breve depoimento
              </h3>
              <div
                [innerHTML]="teacher?.translates?.[0]?.testimonial"
                class="innerHtml"
              >
                <p class="mb-5">
                  <strong class="font-bold">Lorem ipsum dolor sit amet</strong>,
                  consectetur adipiscing elit. Sed ut porttitor velit. Nullam
                  viverra sapien sed tortor fermentum, vel dapibus magna
                  eleifend. Sed nec velit ac nisi scelerisque tristique. In hac
                  habitasse platea dictumst. Vivamus nec consectetur ipsum.
                </p>
                <p>
                  Curabitur semper tincidunt justo, et vehicula lectus posuere
                  vel. Donec consequat neque sit amet sapien sagittis, vel
                  hendrerit turpis tincidunt. Nulla facilisi. Suspendisse
                  potenti. Ut tincidunt ante non ipsum vestibulum, eu tempor
                  dolor lobortis. Sed viverra urna sed aliquam volutpat.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="py-10 bg-black bg-no-repeat bg-[url('/assets/images/bg-2.jpg')] bg-contain bg-top"
  >
    <div
      class="flex justify-center  min-w-[500px] min-h-[500px] m-auto gap-20 align-middle items-center"
    >
    <img src="/assets/images/img-1.jpg"  class="flex-1 max-w-[400px] mb-10"/>
    <img src="/assets/images/img-2.jpg"  class="flex-1 max-w-[400px] mt-10"/>

    <!-- <img [src]="teacher.imageHighlight[0]" *ngIf="teacher.imageHighlight[0]" class="w-full"/> -->
  </div>
  </div>

  <div class="py-10 bg-black pt-20">
    <h3
      class="text-white text-center font-bold text-[50px] font-bolder leading-7 font-gabriella uppercase"
    >
      {{teacher?.translates?.[0]?.titleMoreAbout}}
    </h3>
    <div
      [style.background-image]="
        'url(./assets/images/lights-1.jpg), url(./assets/images/lights-1.jpg)'
      "
      class="flex w-[80%] min-w-[500px] m-auto gap-20 align-middle bg-blend-exclusion items-center bg-contain bg-no-repeat bg-[position:left,_center]"
    >
      <div class="max-w-[50%] flex-1 relative flex items-center">
        <div
          class="text-white w-full innerHtml"
          [innerHTML]="teacher?.translates?.[0]?.textMoreAbout?.textLeft"
        ></div>
      </div>
      <div class="max-w-[27%]">
        <div class="iphone-x max-w-full w-full overflow-hidden !h-[650px]">
          <img
            [src]="'./assets/images/hero-1.jpg'"
            class="h-full w-full object-cover object-center"
          />
          <i></i>
          <b></b>
        </div>
      </div>
      <div
        class="max-w-[35%] flex-1 text-white font-light innerHtml"
        [innerHTML]="teacher?.translates?.[0]?.textMoreAbout?.textRight"
      ></div>
    </div>
  </div>

  <div class="pt-10 pb-40 bg-black px-10">
    <h2 class="text-center text-white font-gabriella my-10 relative">
      <span
        aria-hidden="true"
        class="title-stroke absolute text-[50px] tracking-widest left-0 right-0 -top-6 bottom-0 font-gabriella"
        >Perguntas frequentes</span
      >
      <span class="relative text-[20px] tracking-wide uppercase">
        Perguntas frequentes
      </span>
    </h2>
    <div
      class="flex w-[80%] min-w-[500px] m-auto gap-10 align-middle items-center"
    >
      <div class="max-w-[65%] flex-1 relative flex items-center">
        <img
          [src]="'./assets/images/faq-course-default-image.jpg'"
          class="w-full max-h-[400px] rounded-lg object-cover object-center"
        />
      </div>
      <div
        class="max-w-[35%] flex-1 font-light max-h-[400px] overflow-y-auto pr-2 scrollable text-[#dfdfdf] text-[15px]"
      >
        <div
          class="border-b border-primary"
          *ngFor="let question of questions; let i = index"
        >
          <div
            (click)="toggleAccordion(i)"
            class="my-4 ml-3 pr-10 relative cursor-pointer font-semibold hover:text-white"
          >
            {{ question.question }}
            <img
              [ngClass]="isOpen(i) ? 'rotate-180' : ''"
              [src]="'./assets/images/chevron.svg'"
              class="w-3 absolute top-3 right-1"
            />
          </div>
          <div
            #content
            [style.maxHeight]="isOpen(i) ? content.scrollHeight + 'px' : '0'"
            class="max-h-0 overflow-hidden pl-6 transition-all duration-300 ease-in-out text-white"
          >
            <p class="mb-5">
              <strong class="font-bold">Lorem ipsum dolor sit amet</strong>,
              consectetur adipiscing elit. Sed ut porttitor velit. Nullam
              viverra sapien sed tortor fermentum, vel dapibus magna eleifend.
              Sed nec velit ac nisi scelerisque tristique. In hac habitasse
              platea dictumst. Vivamus nec consectetur ipsum.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div
    class="py-10 bg-black bg-no-repeat bg-[url('/assets/images/bg-2.jpg')] bg-contain bg-top"
  >
    <div
      class="flex justify-center  min-w-[500px] min-h-[500px] m-auto gap-20 align-middle items-center"
    >
    <div class="w-full min-w-[1000px] mt-0">
      <h2 class="text-center text-white font-gabriella my-10 relative">
        <span
          aria-hidden="true"
          class="title-stroke !text-transparent absolute text-[70px] tracking-[50px] left-0 right-0 -top-2 leading-[80px] bottom-0 font-gabriella"
        >
          <span class="text-transparent">Redes</span>
          <br />
          <span class="text-transparent">Sociais</span>
        </span>
        <span class="relative text-[50px] tracking-wide uppercase">
          <span class="text-primary">Redes</span>
          <br />
          <span class="text-tertiary">Sociais</span>
        </span>
      </h2>
      <div class="flex gap-10 justify-center">
        <div class="flex flex-col justify-center items-center gap-3 cursor-pointer">
          <img src="https://img.icons8.com/ios_filled/512/facebook-new.png" class="w-20"/>
          <span class="font-bold">Acesse o Facebook</span>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
<app-footer />
