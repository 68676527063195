import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, Renderer2, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-select',
  template: `
    <label class="block relative">
      <p class="mb-1 font-regular text-[15px]">{{ label }}</p>
      <select
        *ngIf="attrs"
        [ngClass]="attrs['class'] + (error ? ' bg-error-input border-primary' : '')"
        [ngStyle]="attrs['style']"
        [attr.disabled]="attrs['disabled'] ? true : null"
        [attr.name]="attrs['name']"
        [attr.id]="attrs['id']"
        [attr.aria-label]="attrs['aria-label']"
        class="bg-black pr-3 border border-primary rounded-[15px] text-sm text-white px-5 py-4 w-full focus:outline-tertiary"
        [(ngModel)]="value"
        (input)="onInputChange($event)"
      >
        <option *ngIf="placeholder" value="" disabled selected hidden>
          {{ placeholder }}
        </option>
        <option *ngFor="let option of options" [value]="option.value">
          {{ option.label }}
        </option>
      </select>
      <p
        class="text-red-500 text-xs pb-4 relative"
      >
        <span class="absolute top-0">
          {{ error }}
        </span>
      </p>
    </label>
  `,
  styles: `
  select {
    -webkit-appearance: none;
    appearance: none;
  }
  label::after {
    top: 50px;
    right: 13px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-top-color: black;
    border-width: 7px;
    margin-left: -10px;
  }
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  imports: [CommonModule, FormsModule],
  standalone: true
})
export class SelectComponent implements ControlValueAccessor {
  @Input() attrs: { [key: string]: any } = {};
  @Input() options: { value: string | number; label: string }[] = [];
  @Input() label: string = '';
  @Input() placeholder?: string;
  @Input() error: string | null = null;

  value: any;

  onChange = (value: any) => {};
  onTouched = () => {};

  constructor(private elRef: ElementRef, private renderer: Renderer2) {}

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

  onInputChange(event: any): void {
    this.value = event.target.value;
    this.onChange(this.value);
    this.onTouched();
  }

  isStringError(error: any): boolean {
    return typeof error === 'string' || typeof error === 'object';
  }
}
