import { Injectable } from "@angular/core";
import API from "../auth/auth-interceptor.service";

@Injectable({
    providedIn: 'root',
  })
export class SystemService {

  async getAbout(languageCode) {
    try {
      const response = await API.get(`/api/public/system/institutional?languageCode=${languageCode}`);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
