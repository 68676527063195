export function replaceNbspInObject(
  obj: Record<string, any>
): Record<string, any> {
  const updatedObj: Record<string, any> = {};

  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      updatedObj[key] = obj[key].replace(/&nbsp;/g, ' ');
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      updatedObj[key] = replaceNbspInObject(obj[key]);
    } else {
      updatedObj[key] = obj[key];
    }
  }

  return updatedObj;
}
