import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { FooterMinimalComponent } from '../../shared/components/footer-minimal/footer-minimal.component';
import { HeaderMinimalComponent } from "../../shared/components/header-minimal/header-minimal.component";

@Component({
  selector: 'app-public-layout',
  standalone: true,
  templateUrl: './public-layout.component.html',
  styleUrl: './public-layout.component.scss',
  imports: [RouterOutlet, RouterModule, FooterMinimalComponent, HeaderMinimalComponent] 
})
export class PublicLayoutComponent {}