import { CommonModule } from '@angular/common';
import { Component, forwardRef, Input } from '@angular/core';
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR,
  FormControl,
} from '@angular/forms';

@Component({
  selector: 'app-input',
  template: `
    <label class="block">
      <p class="mb-1 font-regular text-[15px]">{{ label }}</p>
      <input
        class="bg-black border border-primary rounded-[15px] text-sm text-white px-5 py-4 w-full focus:outline-tertiary"
        [type]="type || 'text'"
        [ngClass]="(attrs['class']?attrs['class']:'') + (error ? ' bg-error-input' : '') + (attrs['disabled']?'opacity-30':'')"
        [ngStyle]="attrs['style']"
        [disabled]="attrs['disabled'] ? true : null"
        [attr.readonly]="attrs['readonly'] ? true : null"
        [attr.placeholder]="attrs['placeholder']"
        [attr.maxlength]="attrs['maxlength']"
        [(ngModel)]="value"
        (input)="onInputChange($event)"
        [attr.name]="attrs['name']"
        [attr.id]="attrs['id']"
        [attr.aria-label]="attrs['aria-label']"
      />
      <p
        class="text-red-500 text-xs pb-4 relative"
      >
        <span class="absolute top-0">
          {{ error }}
        </span>
      </p>
    </label>
  `,
  imports: [CommonModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
  standalone: true,
})
export class InputComponent implements ControlValueAccessor {
  @Input() attrs: { [key: string]: any } = {};
  @Input() label: string = '';
  @Input() type: string = 'text';
  @Input() error: string | null = null;
  @Input() maskFunction?: (value: string) => string;

  value: any;

  onChange = (value: any) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // Optional: handle disabled state
  }

  isStringError(error: any): boolean {
    return typeof error === 'string' || typeof error === 'object';
  }

  onInputChange(event: any): void {
    let inputValue = event.target.value;

    if (this.maskFunction) {
      inputValue = this.maskFunction(inputValue);
    }

    this.value = inputValue;
    this.onChange(inputValue);
  }
}
