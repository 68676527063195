import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HeaderComponent } from "../../../shared/components/header/header.component";
import { HeroCarouselComponent } from "../../../shared/components/hero-carousel/hero-carousel.component";
import { HoverSlideCarouselComponent } from "../../../shared/components/carousel/hover-slide-carousel/hover-slide-carousel.component";
import { FooterComponent } from "../../../shared/components/footer/footer.component";
import { CommonModule } from '@angular/common';
import { TeachersService } from '../../../services/api/teachers/teachers.service';
import { SpinnerComponent } from "../../../shared/components/spinner/spinner.component";
import { replaceNbspInObject } from '../../../shared/utils/utils';

@Component({
  selector: 'app-teachers-page',
  standalone: true,
  imports: [RouterModule, HeaderComponent, FooterComponent, CommonModule, SpinnerComponent],
  templateUrl: './teachers-page.component.html',
  styleUrl: './teachers-page.component.scss'
})
export class TeachersPageComponent {
  loading=true;
  item = 
    { 
      title: 'NEON LED 1ª e 2ª geração',
      image: './assets/images/hero-1.jpg', 
      description: 'com Vinícius Aquino',
      tag: 'Sugerido para você',
      video: './assets/hero-1.mp4', 
    }

    id;
    teacher;

    activeIndex: number | null = null;
    questions = [
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
    ]
    @ViewChildren('content') contents!: QueryList<ElementRef>;

    constructor (private teacherService: TeachersService, private route: ActivatedRoute) {}

    ngOnInit(){
      this.route.paramMap.subscribe(async (params) => {
        this.id = params.get('id');
        if(this.id) {
          try {
            const teacher = await this.teacherService.get(this.id);
            teacher.translates[0]=replaceNbspInObject(teacher.translates[0])
            this.teacher = teacher
            this.loading=false
          } catch (error) {
          }
        }
      });
    }


    toggleAccordion(index: number): void {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
  
      this.contents.forEach((content, i) => {
        const contentEl = content.nativeElement as HTMLElement;
        if (i === this.activeIndex) {
          contentEl.style.maxHeight = contentEl.scrollHeight + 'px';
        } else {
          contentEl.style.maxHeight = '0';
        }
      });
    }

    isOpen(index: number): boolean {
      return this.activeIndex === index;
    }
}
