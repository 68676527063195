import { Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { HeaderComponent } from "../../../shared/components/header/header.component";
import { FooterComponent } from "../../../shared/components/footer/footer.component";
import KeenSlider from 'keen-slider'
import { CommonModule } from '@angular/common';
import { replaceNbspInObject } from '../../../shared/utils/utils';
import { CourseService } from '../../../services/api/course/course.service';

@Component({
  selector: 'app-course-page',
  standalone: true,
  imports: [RouterModule, HeaderComponent, FooterComponent, CommonModule],
  templateUrl: './course-page.component.html',
  styleUrl: './course-page.component.scss'
})
export class CoursePageComponent {
  item = 
    { 
      title: 'NEON LED 1ª e 2ª geração',
      image: './assets/images/hero-1.jpg', 
      description: 'com Vinícius Aquino',
      tag: 'Sugerido para você',
      video: './assets/hero-1.mp4', 
    }
    @ViewChild('sliderModules') sliderModules!: ElementRef<HTMLElement>;

    constructor(
      private route: ActivatedRoute,
      private courseService: CourseService
    ) {}

    slider: any = null
    slides = Array.from({ length: 8 });
    dotHelper: Array<Number> = []

    id
    loading=true
    course

    activeIndex: number | null = null;
    questions = [
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
      {
        question: "Qual é a diferença entre o curso básico e o curso avançado?",
        answer: "O curso básico é mais básico e o curso avançado é mais avançado.",
      },
    ]
    @ViewChildren('content') contents!: QueryList<ElementRef>;

  
    ngAfterViewInit(){
      this.route.paramMap.subscribe(async (params) => {
        this.id = params.get('id');
        if(this.id) {
          try {
            const course = await this.courseService.get(this.id);
            course.data.translates[0]=replaceNbspInObject(course.data.translates[0])
            course.data.translates[0].richContent = Object.values(course.data.translates?.[0].richContent || {});
            console.log(course.data.translates[0].richContent)
            course.data.translates[0].richContent=course.data.translates[0].richContent.filter(e=>e.title && e.text)
            this.course = course.data
            this.loading=false
            this.slider = new KeenSlider(this.sliderModules.nativeElement, {
              slides: {
                perView: 4,
                spacing: 30,
                number: 8
              },
              drag: false
            })
            this.dotHelper = [
              ...Array(this.slider.track.details.slides.length/4).keys(),
            ]
          } catch (error) {
          }
        }
      });
    }
  
    ngOnDestroy(){
      if(this.slider) this.slider.destroy()
    }

    toggleAccordion(index: number): void {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
  
      this.contents.forEach((content, i) => {
        const contentEl = content.nativeElement as HTMLElement;
        if (i === this.activeIndex) {
          contentEl.style.maxHeight = contentEl.scrollHeight + 'px';
        } else {
          contentEl.style.maxHeight = '0';
        }
      });
    }

    isOpen(index: number): boolean {
      return this.activeIndex === index;
    }
}
