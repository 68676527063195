<app-header />
<div class="min-h-[50vh] flex justify-center items-center" *ngIf="loading">
  <app-spinner />
</div>
<div
  class="flex flex-col justify-center items-center bg-black"
  *ngIf="!loading"
>
  <div
    class="flex flex-col items-center min-h-[500px] bg-no-repeat bg-[url('/assets/images/bg-titles.png')] bg-[length:100%_100%] bg-top w-full"
  >
    <div class="w-full min-w-[1000px] mt-32">
      <h2 class="text-center text-white font-gabriella my-10 relative">
        <span
          aria-hidden="true"
          class="title-stroke !text-transparent absolute text-[70px] tracking-[50px] left-0 right-0 -top-2 leading-[80px] bottom-0 font-gabriella"
        >
          <span class="text-transparent">Nossos</span>
          <br />
          <span class="text-transparent">Professores</span>
        </span>
        <span class="relative text-[50px] tracking-wide uppercase">
          <span class="text-primary">Nossos</span>
          <br />
          <span class="text-tertiary">Professores</span>
        </span>
      </h2>
    </div>
  </div>
</div>

<div class="block min-h-full">
  <div class="py-20 bg-black px-10">
    <div
      class="flex flex-wrap gap-[5%] gap-y-10 justify-start max-w-[1000px] mx-auto"
    >
      <div
        class="w-[30%] bg-gradient-to-b from-[#e60341] to-white rounded-xl p-1 pt-2"
        *ngFor="let item of teachers"
      >
        <div class="w-full rounded-xl bg-white h-full flex flex-col">
          <img
            [src]="'./assets/images/kitty.jpg'"
            aria-hidden="true"
            class="select-none pointer-events-none rounded-bl-[40px] overflow-hidden h-[300px] w-full object-cover object-center"
          />
          <div class="text-black px-5 py-5 flex flex-col flex-1">
            <h3 class="text-[24px] mb-3 font-semibold">{{item.name}}</h3>
            <div [innerHTML]="item.about||''" class="mb-5">
            </div>
            <a
              [routerLink]="'/teachers/'+item.id"
              class="mt-auto bg-transparent text-primary border border-primary px-4 py-3 rounded-lg mt-10 block font-bold ml-auto"
            >
              Ver bio completa
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
<app-footer />
