import { Injectable } from '@angular/core';
import API from '../../../auth/auth-interceptor.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private apiUrl = environment.apiUrl;

  async signUp(body: {
    name: string;
    email: string;
    password: string;
  }) {
    try {
      const response = await API.post(`${this.apiUrl}/api/user`, body);
      return response.data;
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  }

  async updateProfile(userData: {
    userAddress?: {
      zipCode?: string;
      address?: string;
      number?: string;
      complement?: string;
      reference?: string;
      neighborhood?: string;
      city?: string;
      state?: string;
      country: string;
    },
    userInfo: {
      name: string;
      document: string;
      mobile: string;
      gender: string;
      birthday: string;
      photo?: string;
    };
  }) {
    try {
      const response = await API.put(`${this.apiUrl}/api/user`, userData);
      return response.data;
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  }


  async login(userData: {
    email: string;
    password?: string;
  }) {
    try {
      const response = await API.post(`${this.apiUrl}/api/auth/sign-in`, userData);
      return response.data;
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  }


  async resetPassword(userData: {
    code: string;
    password?: string;
  }) {
    try {
      const response = await API.post(`${this.apiUrl}/api/auth/reset-password/`+userData.code, {newPassword: userData.password});
      return response.data;
    } catch (error) {
      console.error('Reset password error:', error);
      throw error;
    }
  }

  async sendRecoverLink(userData: {
    email: string;
  }) {
    try {
      const response = await API.post(`${this.apiUrl}/api/auth/reset-password`, userData);
      return response.data;
    } catch (error) {
      console.error('Reset password error:', error);
      throw error;
    }
  }


  async googleCallBack(token: string) {
    try {
      const response = await API.get(`${this.apiUrl}/api/auth/google/callback?code=${token}`);
      return response.data;
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  }

  async updateLang(lang) {
    try {
      const response = await API.put(`${this.apiUrl}/api/user/language`, {
        languageCode: lang
      });
      return response.data;
    } catch (error) {
      console.error('Signup error:', error);
      throw error;
    }
  }
}