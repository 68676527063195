<ol class="flex items-center w-full mb-10 text-sm font-medium text-center text-gray-800 sm:text-base">
    <li
    *ngFor="let step of steps; let i = index"
    class="flex items-center basis-auto shrink-0"
    (click)="onStepClick(i)"
    [ngClass]="{
        'text-secondary': i < currentStep,
        'text-primary': i == currentStep,
        'sm:after:content-[\'\'] after:w-full after:h-1 after:border-b after:border-1 after:hidden sm:after:inline-block after:mx-1': i!=(steps.length-1),
        'after:border-secondary': i < currentStep,
        'after:border-gray-800': i >= currentStep,
        'grow-0': i == steps.length-1,
        'grow-[1]': i != steps.length-1,

    }"
  >
  <span
    class="flex items-center text-nowrap select-none cursor-pointer"
    >
      <svg
        *ngIf="i < currentStep"
        class="w-6 h-6 me-2.5"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 20 20"
      >
        <path
          d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"
        />
      </svg>
      <span
        *ngIf="i >= currentStep"
        class="me-2 rounded-full w-6 h-6 flex justify-center align-middle items-center text-xs"
        [ngClass]="{
            'text-white bg-primary': i <= currentStep,
            'text-gray-800 border border-gray-800': i > currentStep
        }"
    >{{ i + 1 }}</span>
      {{ step }}
    </span>
  </li>
</ol>
