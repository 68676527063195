<footer class="bg-black text-white pt-10 font-helvetica">
  <div
    class="container mx-auto flex justify-between items-center gap-20 text-center md:text-left my-6"
  >
    <div class="flex-grow-0">
      <div class="flex gap-3">
        <h3 class="select-none inline-block font-extralight">
          {{ 'FOOTER.LANGUAGE' | translate }}
        </h3>
        <button title="Português" (click)="useLanguage('pt-br')">
          <img [src]="'./assets/images/footer/flags/pt-br'+(currentLanguage=='pt-br'?'':'-outline')+'.png'" class="w-7" />
        </button>
        <button title="English" (click)="useLanguage('en-us')">
          <img [src]="'./assets/images/footer/flags/en-us'+(currentLanguage=='en-us'?'':'-outline')+'.png'" class="w-7" />
        </button>
      </div>
    </div>
    <div class="flex-grow-0">
      <div class="flex gap-3 items-center">
        <h3 class="select-none inline-block font-extralight">
          {{ 'FOOTER.SITE_ENCRYPTED' | translate }}
        </h3>
        <a title="">
          <img
            src="./assets/images/ssl.png"
            class="w-28 hover:brightness-150 cursor-pointer"
          />
        </a>
        <a title="">
          <img
            src="./assets/images/secured.png"
            class="w-28 hover:brightness-150 cursor-pointer"
          />
        </a>
        <a title="">
          <img
            src="./assets/images/pagarme.png"
            class="w-28 hover:brightness-150 cursor-pointer"
          />
        </a>
      </div>
    </div>
  </div>

  <div class="text-center md:text-left bg-black pt-3 pb-10">
    <div class="container mx-auto relative text-gray-400">
      <div class="flex gap-4 items-center font-light text-[15px] font-regular">
        <a [routerLink]="'/'">
          <img src="./assets/images/logo-white.svg" class="w-30 mt-2" />
        </a>
        {{ 'FOOTER.ALL_RIGHTS_RESERVED' | translate }}
        <span class="mx-1">⎯</span>
        <a [routerLink]="'/about'" class="hover:text-primary">
          {{ 'FOOTER.ABOUT' | translate }}
        </a>
        <span aria-hidden="true">•</span>
        <a [routerLink]="'/faq'" class="hover:text-primary">
          {{ 'FOOTER.FAQ' | translate }}
        </a>
        <span aria-hidden="true">•</span>
        <a href="#" class="hover:text-primary">
          {{ 'FOOTER.PRIVACY' | translate }}
        </a>
        <span aria-hidden="true">•</span>
        <a href="#" class="hover:text-primary">
          {{ 'FOOTER.TERMS' | translate }}
        </a>
      </div>
    </div>
  </div>
</footer>
