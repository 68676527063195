<div class="flex flex-col justify-center items-center bg-[#F8FAFC]">
  <div class="flex flex-col  items-center min-h-[500px] bg-no-repeat bg-[url('/assets/images/faq-bg.jpg')] bg-contain bg-top w-full">
    <div class="max-w-[800px] mt-32 text-center bg-no-repeat leading-[60px]">
      <h2 class="font-gabriella uppercase text-[52px]">
        <span class="text-primary">Dúvidas</span>
        <br/>
        <span class="text-secondary">Frequentes</span>
        </h2>
    </div>
  </div>
 
  <div class="flex flex-row justify-center items-start min-h-[300px] py-24 pt-5 bg-[#F8FAFC] w-full">
    <div class="flex flex-row w-full p-4 max-w-[1100px]">
      <div class="w-1/3 text-lef">
        <h2 class="text-primary font-medium text-[24px] leading-10 mb-3">Tópicos</h2>
        <ul class="w-full ml-2 pr-5">
          <li *ngFor="let item of categories" class="block w-full font-bold mb-2 cursor-pointer rounded-lg hover:bg-neutral-300 p-3">
            {{item.name}}
          </li>
        </ul>
      </div>
      <div class="w-2/3 text-left ml-2 p-4 bg-white rounded-md">
        <div class="p-5 mb-2" *ngFor="let item of questions">
          <h4 class="font-bold mb-3 text-[17px] relative pr-7">
            {{item.question}}
            <img src="/assets/images/chevron.png" class="rotate-[90] w-7 absolute right-0 top-0" />
          </h4>
          <div>
            {{item.answer}}
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
