export const genders = [
  'male',
  'female',
  'non-binary',
  'transgender',
  'gender-fluid',
  'agender',
  'bigender',
  'gender-queer',
  'other',
  'prefer-not-to-say',
]