import { ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter, Router } from '@angular/router';

import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import {provideHttpClient} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import { ProfileService } from './services/profile.service';
import { APP_INITIALIZER } from '@angular/core';
import { initializeApp } from '../main';

const httpLoaderFactory: (http: HttpClient) => TranslateHttpLoader = (http: HttpClient) => {
  console.log('HttpLoaderFactory initialized');
  return new TranslateHttpLoader(http, './i18n/', '.json');
};

export const appConfig: ApplicationConfig = {
  providers: [
    ProfileService,
    {
      provide: APP_INITIALIZER,
      useFactory: (profileService: ProfileService, router: Router) =>
        initializeApp(profileService, router),
      deps: [ProfileService, Router],
      multi: true,
    },
    provideRouter(routes),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideClientHydration(),
    provideHttpClient(),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: httpLoaderFactory,
          deps: [HttpClient],
        },
      })
    )
  ]
};