import { Component } from '@angular/core';
import { InputComponent } from '../../../shared/components/form/input';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HeaderMinimalComponent } from "../../../shared/components/header-minimal/header-minimal.component";
import { SystemService } from '../../../services/system.service';


@Component({
  selector: 'app-system-about-page',
  standalone: true,
  imports: [
    CommonModule,
    InputComponent,
    RouterModule,
    HeaderMinimalComponent
],
  templateUrl: './system-about-page.component.html',
  styleUrl: './system-about-page.component.scss',
})
export class SystemAboutPageComponent {
  data
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private systemService: SystemService
  ) {

  }

  async ngOnInit() {
    try {
      let lang = localStorage.getItem('language') || 'en-us';
      const response = await this.systemService.getAbout(lang)
      let temp = response.data;
      temp.section=JSON.parse(temp.section)
      this.data=temp
      console.log(this.data)
    } catch (error) {
    }
  }
}
