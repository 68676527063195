<div class="flex flex-col justify-center items-center bg-black">
  <div *ngIf="data" class="flex flex-col  items-center min-h-[800px] bg-no-repeat bg-[url('/assets/images/about-bg.jpg')] bg-contain bg-top w-full">
    <div class="max-w-[800px] mt-32 text-center bg-no-repeat bg-[url('/assets/images/title-banner.png')] bg-[length:100%_100%] bg-top leading-10 py-10 pl-16 pr-10">
      <h2 class="text-white font-gabriella uppercase text-[40px]">{{data.title}}</h2>
      <p class="text-white leading-6 text-left mt-5">{{data.introduction}}</p>
    </div>
  </div>
 
  <div *ngIf="data && data?.section[0]" class="flex flex-col items-center min-h-[300px] py-24 bg-no-repeat bg-[url('/assets/images/about-bg-2.png')] bg-contain bg-center w-full">
    <div class="max-w-[700px] text-left leading-10">
      <h2 class="text-white font-bold text-[24px]">{{data?.section[0].title}}</h2>
      <p class="text-white leading-6 text-left mt-5">
        {{data?.section[0].text}}
      </p>
    </div>
  </div>
  <div *ngIf="data && data?.section[1]" class="flex flex-col items-center justify-center min-h-[900px] bg-no-repeat bg-[url('/assets/images/about-bg-3.jpg')] bg-contain bg-top w-full">
    <div class="max-w-[800px] text-left mb-24 bg-no-repeat bg-[url('/assets/images/title-banner.png')] bg-[length:100%_100%] bg-top leading-10 py-10 pl-24 pr-16">
      <h2 class="text-white font-bold text-[24px]">
        {{data?.section[1].title}}
      </h2>
      <p class="text-white leading-6 text-left mt-5">
        {{data?.section[1].text}}
      </p>
    </div>
  </div>
  <div *ngIf="data && data?.section[2]" class="flex flex-col items-center min-h-[300px] w-full mt-10">
    <div class="max-w-[700px] text-left leading-10 pb-20">
      <h2 class="text-white font-bold text-[24px]">
        {{data?.section[2].title}}
      </h2>
      <p class="text-white leading-7 text-left mt-5">
        {{data?.section[2].text}}
      </p>
    </div>
  </div>
</div>
