import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import 'keen-slider/keen-slider.min.css'
import {
  TranslateService,
  TranslatePipe,
  TranslateDirective,
  _
} from "@ngx-translate/core";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, TranslatePipe, TranslateDirective],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  constructor(private translate: TranslateService) {
    this.translate.addLangs(['pt-br', 'en-us']);
    this.translate.setDefaultLang('en-us');
    const preferedLang = localStorage.getItem("language");
    this.translate.use(preferedLang || this.translate.getBrowserLang() || "en-us");
  }
}
